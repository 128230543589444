import { FC, useContext, useEffect, useId, useState } from "react"
import { useNavigate } from "react-router-dom"

import "./index.css"

import { DragDrop } from "@uppy/react"

import { Button, FileUploadArea } from "@appia/ui-components"
import ErrorCallout from "@appia/ui-components/src/Error"
import LoadingOverlay from "src/components/LoadingOverlay"
import PageWrapper from "src/components/PageWrapper"

import {
  ApiClient,
  UploadStatus,
  confirmUpload,
  failUpload,
  getStatus,
  startStubFlow,
  startUploadFlow,
} from "src/api"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import { SOV } from "src/common/types"

import useApiClient from "src/contexts/ApiClientContext"
import TokenContext from "src/contexts/TokenContext"
import useConfig from "src/contexts/ConfigContext"

import usePageLoad from "src/common/hooks"
import { trackHeapEvent } from "src/heap"

import useUppy from "@appia/file-upload"

const FILETYPE_MSG = "File formats accepted: Excel (.xls and .xlsx) and CSV"
export const UPLOAD_TEST_ID = "upload-screen"

interface UploadState {
  sovId: string
  documentId: string
  uploadStatus: UploadStatus["document_status"]
  fileName: string
}

const UploadFileSection: FC<{
  acceptedDocumentTypes: string[]
  apiClient: ApiClient
  token: string
  maxMegabytes: number
  onUploadSuccess: (sovId: SOV["id"]) => void
}> = ({
  acceptedDocumentTypes,
  apiClient,
  token,
  maxMegabytes,
  onUploadSuccess,
}) => {
  const navigate = useNavigate()

  const [uploadRequest, setUploadRequest] = useState<
    RD.RemoteData<Error, UploadState>
  >(RD.NotAsked)

  const uppy = useUppy({
    maxMegabytes,
    acceptedDocumentTypes,
    getUploadParameters: async (file, uppy) => {
      if (typeof file.type === "undefined") {
        throw new Error(`Unrecognised document type. ${FILETYPE_MSG}`)
      }

      const urlRes = await startUploadFlow(apiClient, token, {
        name: file.name,
        mimetype: file.type,
        extension: file.extension.toLowerCase(),
      })

      if (urlRes.status !== 201) {
        throw new Error(`We were unable to accept your upload. ${FILETYPE_MSG}`)
      }

      const {
        id: documentId,
        sov_id: sovId,
        url,
        fields,
        redirect_uri: redirectUri,
        failure_uri: failureUri,
      } = urlRes.data

      uppy.setFileMeta(file.id, {
        documentId,
        sovId,
        redirectUri,
        failureUri,
      })

      return { url, fields }
    },
    onStartLoad: () => setUploadRequest(RD.Loading),
    onSuccess: async (_, file) => {
      if (!file) {
        return
      }

      const sovId = file.meta["sovId"] as string
      const documentId = file.meta["documentId"] as string

      setUploadRequest(
        RD.Success({
          sovId,
          documentId,
          uploadStatus: "uploading",
          fileName: file.name,
        }),
      )

      await confirmUpload(apiClient, sovId)
    },
    onError: async uppyError => {
      Sentry.captureException(uppyError.error)

      switch (uppyError.type) {
        case "upload-error": {
          if (uppyError.file) {
            const sovId = uppyError.file.meta["sovId"] as SOV["id"]
            await failUpload(apiClient, sovId)
            setUploadRequest(RD.Failure(new Error("Upload failed")))
          } else {
            setUploadRequest(RD.Failure(uppyError.error))
          }

          break
        }
        case "restriction-failed": {
          const customFileTypeError = new Error(
            "Please retry using an SOV in Excel (xlsx, xls) or CSV format",
            { cause: uppyError.error },
          )

          const customError = uppyError.error.message.includes(
            "You can only upload",
          )
            ? customFileTypeError
            : uppyError.error

          setUploadRequest(RD.Failure(customError))

          break
        }
        default: {
          setUploadRequest(RD.Failure(uppyError.error))
        }
      }
    },
  })

  useEffect(() => {
    const handler = setTimeout(async () => {
      if (RD.isSuccess(uploadRequest)) {
        const { sovId, documentId, uploadStatus, fileName } = uploadRequest.data
        switch (uploadStatus) {
          case "failed":
            uppy.emit(
              "error",
              new Error(
                "Something went wrong with your upload. Please try again.",
              ),
            )
            break
          case "uploading":
          case "uploaded": {
            try {
              const status = await getStatus(apiClient, sovId, documentId)
              setUploadRequest(
                RD.Success({
                  ...uploadRequest.data,
                  uploadStatus: status.data.document_status,
                }),
              )
            } catch (e) {
              uppy.emit("error", e)
            }
            break
          }
          case "extracted": {
            navigate(`/${sovId}/extraction`, {
              state: {
                fileName,
              },
            })
            break
          }
        }
      }
    }, 2_000)

    return () => clearTimeout(handler)
  }, [apiClient, navigate, uppy, uploadRequest, onUploadSuccess])

  const uploadHeadingId = useId()

  return (
    <section
      aria-labelledby={uploadHeadingId}
      data-testid={`${UPLOAD_TEST_ID}-section`}
    >
      <h2
        className="sr-only"
        id={uploadHeadingId}
        data-testid={`${UPLOAD_TEST_ID}-sub-header`}
      >
        Upload SOV
      </h2>

      <FileUploadArea
        label={
          <div className="grid gap-2">
            <p className="text-xl font-bold">Upload a new SOV</p>
            <ul className="grid list-inside list-disc gap-2 text-center text-base">
              <li>{FILETYPE_MSG}</li>
              <li>Maximum data per sheet: 20,000 rows and 270 columns</li>
            </ul>
          </div>
        }
        input={
          <DragDrop
            uppy={uppy}
            className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-0"
          />
        }
      />

      {RD.match(
        uploadRequest,

        null,

        <LoadingOverlay message="Uploading" />,

        ({ uploadStatus }) => {
          switch (uploadStatus) {
            case "failed":
              return null
            case "uploading":
              return <LoadingOverlay message="Confirming your upload" />
            case "uploaded":
              return <LoadingOverlay message="Extracting your data" />
            case "extracted":
              return (
                <LoadingOverlay message="Processing complete, preparing the schedules" />
              )
          }
        },

        error => (
          <div className="mt-4">
            <ErrorCallout message="Failed to upload" error={error} />
          </div>
        ),
      )}
    </section>
  )
}

const SkipUploadSection: FC<{
  apiClient: ApiClient
  token: string
  onSkipUpload: (sovId: SOV["id"]) => void
}> = ({ apiClient, token, onSkipUpload }) => {
  const skipHeadingId = useId()

  const [stubRequest, setStubRequest] = useState<RD.RemoteData<Error, null>>(
    RD.NotAsked,
  )

  return (
    <section
      aria-labelledby={skipHeadingId}
      className="mt-8 border-t border-t-otto-grey-300 pt-8 text-center"
    >
      <h2 className="sr-only" id={skipHeadingId}>
        Continue without SOV
      </h2>

      <Button
        data-testid={`${UPLOAD_TEST_ID}-no-sov-button`}
        label="Enter data manually"
        style="outlined"
        theme="night"
        isLoading={RD.isLoading(stubRequest)}
        onClick={async () => {
          setStubRequest(RD.Loading)

          try {
            const {
              data: { id },
            } = await startStubFlow(apiClient, token)

            onSkipUpload(id)
          } catch (e) {
            Sentry.captureException(e)

            if (e instanceof Error) {
              setStubRequest(RD.Failure(e))
            }
          }
        }}
      />

      {RD.isFailure(stubRequest) && (
        <div className="mt-4">
          <ErrorCallout message="Failed to upload" error={stubRequest.error} />
        </div>
      )}
    </section>
  )
}

const PAGE_TITLE = "Upload SOV"

const UploadScreen: FC = () => {
  const headingRef = usePageLoad(PAGE_TITLE)

  const apiClient = useApiClient()
  const navigate = useNavigate()

  const token = useContext(TokenContext)
  const config = useConfig()

  return (
    <PageWrapper showBackModal={false}>
      <div className="mx-auto w-full max-w-5xl p-4">
        <h1
          ref={headingRef}
          tabIndex={-1}
          className="sr-only"
          data-testid={`${UPLOAD_TEST_ID}-main-header`}
        >
          {PAGE_TITLE}
        </h1>

        <UploadFileSection
          acceptedDocumentTypes={config.acceptedDocumentTypes}
          apiClient={apiClient}
          token={token}
          maxMegabytes={config.sizeLimitMb}
          onUploadSuccess={sovId => {
            trackHeapEvent({ type: "FileUploaded", sovId })
            navigate(`/${sovId}/extraction`)
          }}
        />

        <SkipUploadSection
          apiClient={apiClient}
          token={token}
          onSkipUpload={sovId => {
            trackHeapEvent({ type: "UploadSkipped", sovId })
            navigate(`/${sovId}/manual`, {
              state: {
                populateBlankAssets: true,
              },
            })
          }}
        />
      </div>
    </PageWrapper>
  )
}

export default UploadScreen
